const links = document.querySelectorAll('.caption');
var slides = document.querySelectorAll('#serie .image');
var currentSlide = 0;
var legends = document.querySelectorAll('#caroussel .legende');
var currentLegend = 0;
var divName = 'arrow';
var obj = document.getElementById(divName).style;
var prev = document.getElementById("prev");
var next = document.getElementById("next");
var header = document.getElementById("header");
var divNameHeader = 'infosheader';
var objHeader = document.getElementById(divNameHeader).style;
var offX = 0;
var offY = 0;

// Caroussel

next.addEventListener('click', nextSlide)
window.onload = showSlide();

function showSlide() {
    currentSlide = (currentSlide)%slides.length;
    slides[currentSlide].classList.add('showing');
    currentLegend = (currentLegend)%legends.length;
    legends[currentLegend].classList.add('showing');
}

function nextSlide() {
    slides[currentSlide].className = 'image';
    currentSlide = (currentSlide+1)%slides.length;
    slides[currentSlide].classList.add('showing');
    legends[currentLegend].className = 'legende';
    currentLegend = (currentLegend+1)%legends.length;
    legends[currentLegend].classList.add('showing');
}

prev.addEventListener('click', prevSlide)

function prevSlide() {
	if(currentSlide == 0){
		slides[currentSlide].className = 'image';
		currentSlide = slides.length-1;
    	slides[currentSlide].classList.add('showing');
        legends[currentLegend].className = 'legende';
        currentLegend = legends.length-1;
        legends[currentLegend].classList.add('showing');
	}
	else{
		slides[currentSlide].className = 'image';
    	currentSlide = (currentSlide-1)%slides.length;
    	slides[currentSlide].classList.add('showing');
        legends[currentLegend].className = 'legende';
        currentLegend = (currentLegend-1)%legends.length;
        legends[currentLegend].classList.add('showing');
	}
}

document.onkeydown = checkKey;

function checkKey(e) {

    e = e || window.event;

    if (e.keyCode == '37') {
       prevSlide();
    }
    else if (e.keyCode == '39') {
       nextSlide();
    }

}

// Curseur

function mouseX(evt) {if (!evt) evt = window.event; if (evt.pageX) return evt.pageX; else if (evt.clientX)return evt.clientX + (document.documentElement.scrollLeft ?  document.documentElement.scrollLeft : document.body.scrollLeft); else return 0;}
function mouseY(evt) {if (!evt) evt = window.event; if (evt.pageY) return evt.pageY; else if (evt.clientY)return evt.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop); else return 0;}

function follow(evt) {
    obj.left = (parseInt(mouseX(evt))+offX) + 'px';
    obj.top = (parseInt(mouseY(evt))+offY) + 'px';
}
document.onmousemove = follow;

function arrownext() {obj.transform = 'rotate(180deg)';obj.display = 'block';}
function arrowprev() {obj.transform = 'rotate(0deg)';obj.display = 'block';}
function arrowhide() {obj.display = 'none';}

if (window.matchMedia('(min-width: 640px)').matches) {
    next.addEventListener('mouseenter', arrownext)
    prev.addEventListener('mouseenter', arrowprev)
    next.addEventListener('mouseleave', arrowhide)
    prev.addEventListener('mouseleave', arrowhide)
}

// Afichage header

function showinfosheader() {objHeader.display = 'block';}
function hideinfosheader() {objHeader.display = 'none';}
header.addEventListener('mouseenter', showinfosheader)
header.addEventListener('mouseleave', hideinfosheader)
